export class FeedItemModel {
  feed_full_media_url: string;
  feed_content: string;
  feed_id: number;
  feed_title: string;
  feed_likes: number;
  feed_total_comments: number;
  feed_media_data: any;
  count_feed_media: any;
}

export class FeedListingModel {
  items: Array<FeedItemModel> = [
    new FeedItemModel(),
    new FeedItemModel(),
    new FeedItemModel(),
    new FeedItemModel()
  ];
  start: number;

  constructor(readonly isShell: boolean) { }
}
