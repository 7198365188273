import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ShellProvider } from '../utils/shell-provider';

import { FeedListingModel } from './listing/feed.model';
import { FeedCommentListingModel } from './comment/feed-comment.model';

import { environment } from '../../environments/environment';

@Injectable()
export class FeedService {
  private _listingWithShellCache: ShellProvider<FeedListingModel>;
  private _listingFeedCommentWithShellCache: ShellProvider<FeedCommentListingModel>;

  constructor(private http: HttpClient) { }

  // public postComment(
  //   userId: string,
  //   comment: string,
  //   name: string,
  //   email: string,
  //   feedId: string
  // ) {
    public postComment(
      comment: string,
      feedId: string,
      userId: any
    ) {

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };

    const data = {
      'user_id': userId,
      'comment_content': comment,
      'feed_id': feedId
    };

    this.http.post(environment.api_url + 'admin/feed/api/add_comment', data, httpOptions)
    .subscribe((res) => {
      console.log(res);
    });
  }

  public getFeed(clubId: any, start: any): Observable<FeedListingModel> {
    const shellModel: FeedListingModel = new FeedListingModel(true);
    const dataObservable = this.http.get<FeedListingModel>(environment.api_url + 'admin/feed/api/get_feed/' + clubId + '/' + start);

    this._listingWithShellCache = new ShellProvider(
      shellModel,
      dataObservable
    );
    return this._listingWithShellCache.observable;
  }

  public newGetFeed(clubId: any, start: any, userId: any) {
    return new Promise((resolve, reject) => {

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

      const data = {
        'club_id': clubId,
        'start': start,
        'user_id': userId
      };

      // this.http.get(environment.api_url + 'admin/feed/api/get_feed/' + clubId + '/' + start)
      this.http.post(environment.api_url + 'admin/feed/api/get_feed/', data, httpOptions)
      .subscribe((result: any) => {
        // tslint:disable-next-line: triple-equals
        if (result.code == 0) {
          resolve(result);
        } else {
          reject('Data ga ada');
        }
      }, (err: any) => {
        reject(err);
      });
    });
  }

  public newGetFeedComment(feedId: any, start: any = 0) {
    return new Promise((resolve, reject) => {
      this.http.get(environment.api_url + 'admin/feed/api/get_feed_comment/' + feedId + '/' + start)
      .subscribe((result: any) => {
        // tslint:disable-next-line: triple-equals
        if (result.code == 0) {
          resolve(result);
        } else {
          reject('Data ga ada');
        }
      }, (err: any) => {
        reject(err);
      });
    });
  }

  public getFeedComment(feedId: string): Observable<FeedCommentListingModel> {
    const shellModel: FeedCommentListingModel = new FeedCommentListingModel(true);
    const dataObservable = this.http.get<FeedCommentListingModel>(environment.api_url + 'admin/feed/api/get_feed_comment/' + feedId);

    this._listingFeedCommentWithShellCache = new ShellProvider(
      shellModel,
      dataObservable
    );

    return this._listingFeedCommentWithShellCache.observable;
  }

  public addLike(feedId, userId) {
    return new Promise((resolve, reject) => {

      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type':  'application/json'
        })
      };

      const data = {
        'feed_id': feedId,
        'user_id': userId
      };

      this.http.post(environment.api_url + 'admin/feed/api/add_like', data, httpOptions)
      .subscribe((res: any) => {
        resolve(res);
      }, (err: any) => {
        reject(err);
      });
    });
  }
}
