export class FeedCommentItemModel {
  comment_content: string;
  comment_date_display: string;
  user_name: string;
}

export class FeedCommentListingModel {
  items: Array<FeedCommentItemModel> = [
    new FeedCommentItemModel(),
    new FeedCommentItemModel(),
    new FeedCommentItemModel(),
    new FeedCommentItemModel()
  ];

  constructor(readonly isShell: boolean) { }
}
