export class RollCallDetailsItemModel {
  comment_content: string;
  comment_date_display: string;
  user_name: string;
}

export class RollCallDetailsItemListingModel {
  items: Array<RollCallDetailsItemModel> = [
    new RollCallDetailsItemModel(),
    new RollCallDetailsItemModel(),
    new RollCallDetailsItemModel(),
    new RollCallDetailsItemModel()
  ];

  constructor(readonly isShell: boolean) { }
}
