import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { ShellProvider } from '../utils/shell-provider';

import { RollCallListingModel } from './listing/rollcall.model';
import { RollCallDetailsItemListingModel } from './details/rollcall-details.model';

import { environment } from '../../environments/environment';

@Injectable()
export class RollCallService {
  private _listingWithShellCache: ShellProvider<RollCallListingModel>;
  private _listingRollCallDetails: ShellProvider<RollCallDetailsItemListingModel>;

  constructor(private http: HttpClient) { }

  public getRollCall(clubId: any, includeDate: any = false) {
    return new Promise((resolve, reject) => {
      let apiUrl = '';
      if (includeDate) {
        apiUrl = environment.api_url + 'admin/roll_call/api/get_roll_call/' + clubId + '/' + includeDate;
      } else {
        apiUrl = environment.api_url + 'admin/roll_call/api/get_roll_call/' + clubId;
      }
      this.http.get(apiUrl)
      .subscribe((res: any) => {
        if (res.code === 0) {
          resolve(res);
        } else {
          reject(false);
        }
      }, (err: any) => {
        reject(err);
      });
    });
  }

  public getRollCallDetails(rollCallId: string): Observable<RollCallDetailsItemListingModel> {
    const shellModel: RollCallDetailsItemListingModel = new RollCallDetailsItemListingModel(true);
    // tslint:disable-next-line: max-line-length
    const dataObservable = this.http.get<RollCallDetailsItemListingModel>(environment.api_url + 'admin/roll_call/api/get_roll_call_details/' + rollCallId);

    this._listingRollCallDetails = new ShellProvider(
      shellModel,
      dataObservable
    );

    return this._listingRollCallDetails.observable;
  }
}
